@font-face {
    font-family: 'AudiType-ExtendedBold';
    src: url('../public/fonts/AudiType-ExtendedNormal_4.03.woff') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-size: '1.125rem';
  }
  
  body {
    font-family: "AudiType-ExtendedBold", Verdana, sans-serif;
    font-feature-settings: "kern" 1 ,"liga" 1, "calt" 1, "locl" 1;
    -webkit-font-feature-settings: "kern" 1 ,"liga" 1, "calt" 1, "locl" 1;
    -moz-font-feature-settings: "kern" 1 ,"liga" 1, "calt" 1, "locl" 1;
    -ms-font-feature-settings: "kern" 1 ,"liga" 1, "calt" 1, "locl" 1;
    -o-font-feature-settings: "kern" 1 ,"liga" 1, "calt" 1, "locl" 1;
}

@font-face {
    font-family: 'AudiType-Normal';
    src: url('../public/fonts/AudiType-Normal_4.03.woff') format('truetype');
    font-weight: normal;
    font-style: normal;
   
  }

  @font-face {
    font-family: 'AudiType-WideBold';
    src: url('../public/fonts/AudiType-Normal_4.03.woff2') format('truetype');
    font-weight: normal;
    font-style: normal;
  }