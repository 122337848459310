.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12.5rem;
}
 
.loader {
  border: 0.313rem solid #d9d9d9;
  border-top: 0.313rem solid #eb0d3f;
  border-radius: 50%;
  width: 3.75rem;
  height: 3.75rem;
  animation: spin 0.7s linear infinite;
}

@media (min-height: 1080px) {
  .scrollView {
    min-height: 55rem;  }
}

 
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
 
  .container {
    width: 100%;
    max-width: 300rem;
    margin: 0 auto;
  }
  .customTopBar {
    width: 100%;
    margin-top: 1.25rem;
    display:flex;
    align-items: center;
    padding-right: 0.625rem;
    justify-content: space-between; 
  }

  .customTopBar a { 
    font-family: 'AudiType-ExtendedBold';
    color: 	#666666;
    text-decoration: none;
    transition: all 0.3s ease;
    padding-bottom: 0.313rem;
    position: relative;
    margin-right: 1rem;
    align-items: center;
    padding: 0.5rem 0;
  }
  .customTopBar a::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0.125rem;
    bottom: 0;
    left: 50%;
    background-color: #000000;
    transition: all 0.3s ease;
    transform: translateX(-50%);
}

.customTopBar a:hover, 
.customTopBar a.active-link { 
  color: #000000;
  font-weight: bold;

}
.customTopBar a:hover::before, 
.customTopBar a.active-link::before {
    width: 100%;
    background-color: #000000;
}

.customTopBar a.active-link {
  font-weight: bold;
}

.imageDetailCard {
    width: 100%;
    margin-bottom: 1.25rem;
    position: relative;
    color: #f2f2f2;
  }
.marginBottom {
    margin-bottom: 1.25rem;
  }
.expandImageContainer {
    margin-top: 1.25rem;
    margin-bottom: 3.75rem;
  }
.cveCard {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .boldCveTitle {
    font-weight: bold;
  }
/* CustomTableImage, CustomTableCVE*/

.scrollView {
    max-height: 100%; 
    height:18rem;
    width: 100%;
    margin-top: 1.25rem;
  }

  .justify-text {
    text-align: justify;
}
/* CustomTableImage */

.label {
  display: inline;
  padding: 0.2rem 0.4rem 0.2rem;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #ffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.label-danger {
  background-color: #f50537;
}

.cellColor {
    color: blue;
  }
.cellLink {
    text-decoration: underline;
  }
.hazardSignOne {
    color: #eb0d3f;;
    margin-right: 0.313rem;
  }
.hazardSignTwo {
    color: #ffaa00;
    margin-right:  0.313rem;
  }
.hazardSignThree {
    color: #0da20d;
    margin-right:  0.313rem;
  }
  .tooltipParent {
    position: relative;
    display: inline-block;
    align-items: center; 
    justify-content: center; 
  }


.tooltip {
    position: absolute;
    text-align: center;
    font-size: smaller;
    border-radius: 0.25rem;
    padding: 0.125rem 0.313rem;
    z-index: 1;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
}

.tooltip-critical {
    background-color: #eb0d3f;
    color: white;
}

.tooltip-high {
    background-color: #ffaa00;
    color: white;
}

.tooltip-medium {
    background-color:#0da20d;
    color: white;
}

.customButton {
    color: rgb(4, 4, 4);
    margin-left: 0.438rem;
    height: 2rem;
    width: 6rem;
    background-color: transparent;
    border: 0.063rem solid #000000;
    transition: background-color 0.3s, color 0.3s;
}

.customButton:hover {
  color: #ffffff;
  background-color: #000000;
  border: 0.063rem solid #000000;
}
  
/* FilterOrg */
.select-field-input {
    background-color: white;
    border: 0.063rem solid;
  }
  
.filter-button {
    color: white;
    margin-top: 1.875rem;
    background-color: #000000;
  }

/* ImageCollection */
.text-field {
  size: large;
  width: 35%;
  border:0.063rem solid #ffffff;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  transition: width 0.3s ease;
  font-family: 'AudiType-ExtendedBold';

}
.text-field:focus-within {
  width: 100%;
}

.text-field-input {
    background-color: #f2f2f2;
    border: 0.063rem solid #808080;
    padding-right: 2.188rem;

  }
.inner-end-component {
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 0.5rem;
  }
.card-style {
    background-color:#f2f2f2;
    height:auto;
    border-radius: medium;
    max-width: 100%;
    font-family: 'AudiType-ExtendedBold';
  }
.flex-row {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

.flex-row > .flex-row-item {
    flex-direction: row;
    gap: 0.375rem;
  }

  .image-style {
    width: 6rem; 
    height: auto; 
    padding: small; 
    object-fit: contain;  
  }
  
  
.button-style {
    height: 4rem;
    width: 10rem;
    background-color: #000000;    
  }
.button-style:hover {
    color:#ffffff;
    background-color: #4c4c4c;
  }
  
.badge-style {
    margin-right: 0.5rem;
    color:#0da20d;
    background-color: #ffffff;
    padding: 0.25rem 0.5rem;
    font-size: 0.8rem;
}

/* ImageDetailCard */
.view-container {
  background-color: #f2f2f2;
  padding: 0.2rem;
}
.row-flex {
  direction: row;
  align-items: flex-start;
}

.column-flex {
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.image-width {
  width: 10%;
}

/* NavigationHeader , TopBar*/

.flex-bar {
    gap: 1.75rem;
    direction: row;
    width: 100%;
    max-width: 89rem;
    height: auto;
    justify-content: flex-start;
    align-items: center;
    position: relative;    
  }
  
.text-style {
    font-family: 'AudiType-ExtendedBold';
    margin: 0.625rem 0.625rem;
    font-size: 1.2rem;
    font-weight: 500;
    color: #000000;
    line-height: 0.188rem;
    text-align: center;
    display: block;
    flex-direction: column;
    justify-content: unset;
    width: unset;
    height: unset;
    gap: unset;
    align-items: unset;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    align-self: stretch;
    position: relative;
    padding: 0;
    white-space: pre-wrap;
  }
  .logo-image {
    width: rem;
    height: 1.9rem;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    align-self: center;
}

  
  /* UsageComponent */
  .deployment-container {
    border: 0.063 solid #ddd;
    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.1);
    font-family: 'Arial', sans-serif;
    margin-bottom: 1.25rem; 
  }
  
  .expandable-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.938rem;
    background-color: #f8f8f8;
    cursor: pointer;
    border-bottom: 0.063rem solid #ddd;
  }
  
  .expandable-header h2 {
    font-family: 'AudiType-ExtendedBold';
    font-size: 1rem;
    color: #333;
    margin: 0;
  }
  
  .arrow {
    display: inline-block;
    margin-left: 0.625rem;
    border-left: 0.438rem solid transparent;
    border-right: 0.438rem solid transparent;
    border-top: 0.438rem solid #333; /* Arrow down */
    transition: transform 0.3s ease;
  }
  
  .arrow.up {
    transform: rotate(180deg); /* Arrow up */
  }
  .tabs {
    display: flex;
    justify-content: center;
    background-color: #f9f9f9;
    padding: 0.625rem 0;
    border-bottom: 0.063rem solid #ccc;
  }
  .tab-button {
    padding: 0.5rem 0.125rem;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 0.875rem;
    font-family: 'AudiType-ExtendedBold';
    color: #333;
    outline: none;
    transition: background-color 0.3s, color 0.3s;
    margin-right: 3.125rem;
    position: relative;
  }
  .tab-button:hover, .tab-button.selected {
    background-color: #e6e6e6;
    color: #000;
  }
  .tab-button.selected::after {
    content: '';
    position: absolute;
    bottom: -0.125rem;
    left: 0;
    right: 0;
    height: 0.125rem;
    background-color: #000;
  }

  
  .content {
    padding: 0.938rem;
    background-color: #fff;
    border-top: 0.063rem solid #ddd;
  }
  
  .document {
    padding: 1.25rem;
    background-color: #f9f9f9;
    border: 0.063rem solid #ddd;
    margin-top: 0.625rem;
    border-radius: 0.25rem;
    width: 95%;
    margin: 0 auto;
    font-size: 0.938rem;
    font-family: 'AudiType-Normal';
  }
  
  /* StyledCodeBlock.css */
.code-container {
  position: relative;
  border: 0.063rem;
  border-radius: 0.375rem;
  padding: 0.125rem;
  overflow: auto;
  transition: background-color 0.2s;
  width: 95%;
  margin: 1.25rem auto;
}

.code-container.active {
  background-color: #dbf3db;
}

.code-container pre {
  margin: 0;
}

.code-container code {
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;
  font-size: 1em;
  line-height: 1.45;
  white-space: pre;
  display: block;
}


.copy-button {
  position: absolute;
  right: 1em;
  top: 1em;
  background-color: #f0f0f0;
  border: 0.063rem solid #d0d0d0;
  padding: 0.125rem 0.25rem;
  border-radius: 0.313rem;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
}
.copy-button:hover {
  background-color: #cecdcd;
}

.copy-button:active, .copy-button.copied {
  background-color: #cecdcd;
}

.copy-icon {
  width: 1.25rem;
  height: 1.25rem;
  transition: fill 150ms ease-in-out;
}

  
/* SupportComponent.css */

.support-container {
  padding: 1.25rem; 
  margin: 1.25rem; 
  border: 0.0625rem solid #dddd;
  border-radius: 0.5rem; 
  background-color: #f9f9f9;
  text-align: center;
}

.support-title {
  color: #333;
  margin-bottom: 0.9375rem;
  font-family: 'AudiType-ExtendedBold';
}

.support-content {
  color: #666;
  font-family: 'AudiType-ExtendedBold';

}

.ticket-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  font-family: 'AudiType-ExtendedBold';
}

.redirect-icon {
  margin-left: 0.5rem;
}

/* DashboardComponent.css */

html, body {
  height: 100%;
  margin: 0;
}

#root { /* Adjust this selector if your main wrapper has a different id or class */
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.dashboard-container {
  flex: 1;
  display: flex;
  font-family: 'Arial', sans-serif;
  color: #333;
  background-color: #f4f4f4;
  flex-direction: column; 
}

.dashboard-header {
  background-color: #808080;
  color: #fff;
  padding: 1rem;
  text-align: center;
  font-family: 'AudiType-ExtendedBold';

}

.dashboard-main {
  padding: 4rem;
  flex: 1;

}

.dashboard-footer {
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #6A767D;
  padding: 1rem; 
}

.footer-content {
  max-width: 150rem;
  padding: 0 0.625rem;
  margin: 0 auto;
  text-align: justify;
  font-size: 0.825rem;
}

.dashboard-section {
  font-family: 'AudiType-ExtendedBold';
  background-color: #fff;
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.dashboard-section h2 {
  color: #000;
  margin-bottom: 0.5rem;
}
.sign-out-button {
  padding: 0rem 0.2rem;
  font-size: 0.9rem;
  background-color: #c1c1c1;
  border: 0.0625rem solid #9d9d9d;
  cursor: pointer;
  border-radius: 0.2rem;
  font-family: 'AudiType-Normal';
  color: #5c5c5c;
  transition: all 0.3s ease;
  margin-right: 0.2rem; 
}

.sign-out-button:hover {
  color: #ffffff;
  background-color: #808080;
}

.notice-banner {
  background-color: #ffaa00;
  color: #333;             
  padding: 0.625rem;           
  border-radius: 0.3125rem;     
  margin: 0.625rem 0;        
  text-align: center;      
  font-weight: bold;       
}
.notice-banner p {
  margin: 0;       
  font-size: 1rem;         
}


/* SSOLogin.css */
.login-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sso-login {
  text-align: right;
  margin-bottom: 1.25rem;
}

.cognito-login {
  text-align: left;
}

.sso-login-button-container {
  text-align: center;
}

.sso-login-button-container p {
  margin-bottom: 0.625rem;
}

.sso-login-button {
  background-color: rgb(41, 178, 224);
  color: white;
  padding: 0.6rem 1rem;
  border: none;
  cursor: pointer;
  text-align: center;
  display: block;
  margin: 0 auto;
  border-radius: 0.313rem;
}

.sso-login-button:hover {
  background-color: rgb(15, 147, 190);
}

.or-separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 1.25rem 0;
}

.or-separator .line {
  flex-grow: 1;
  height: 0.063rem;
  background-color: #ccc;
  border: none;
}

.or-separator .or-text {
  margin: 0 0.625rem;
  color: #888;
}

.sso-logo {
    margin-right: 0.938rem;
    height: 1.875rem;
    width: 1.875rem;
    vertical-align: middle;
}
